.ltx-wrapper {
  padding: 1rem;
  margin-bottom: 2rem;
}

.ltx-search-org-wrapper {
  margin: 1.5rem 0.5rem;
  max-width: 600px;
}
.ltx-search-org {
  display: flex;
  gap: 1rem;
}
.ltx-search-org-button {
  margin-top: 0.25rem;
}
.ltx-search-org-results-wrapper {
  position: absolute;
}
.ltx-search-org-results {
  z-index: 50;
}
.ltx-search-org-results-card {
  max-height: 280px;
  overflow-y: scroll;
}
.ltx-search-org-result-row {
  /*padding: 0.5rem 0;*/
}

.ltx-table .ag-cell {
  font-size: 1rem;
  user-select: text;
  cursor: auto;
}
.ltx-two-line-cell {
  line-height: 1.25rem;
}
.ltx-sub-value-line {
  font-size: 85%;
  opacity: 0.7;
}
.ltx-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ltx-table-search-wrapper {
  max-width: 32rem;
}
