.col-span-1 {
  width: 150px;
}

.col-span-2 {
  width: 300px;
  min-width: 300px;
}

.modal-body {
  overflow: auto;
  height: 900px;
}

.filter-div {
  display: flex;
  padding: 16px;
  margin-top: 16px;
  border-top: 1px solid #c2bdbd;
  min-height: 400px;
  border-bottom: 1px solid #c2bdbd;
}

.filter-menu {
  width: 170px;
  overflow: auto;
  background-color: #e0e0e0;
}

.filter-menu span {
  font-weight: 600;
  font-family: Open Sans, Roboto, sans-serif;
}

.filter-values {
  margin-left: 16px;
  min-width: 600px;
}

.filter-radio-group div {
  display: grid !important;
}

.pagination-footer {
  display: flex;
  float: right;
  padding: 5px;
  width: 400px;
}

.pagination-prev {
  margin: 8px 12px !important;
}
.pagination-next {
  display: block;
  margin: 8px 12px !important;
}

.pagination-dropdown {
  max-width: 150px;
}

.empty-data {
  margin: 60px;
  text-align: center;
}

.DayPickerNavigation_button__horizontal {
  position: unset !important;
}

.header-audit-use-text {
  margin-top: 20px;
  margin-bottom: 20px;
  /* font-family: Open Sans,Roboto,sans-serif; */
  font-family: roboto;
  font-weight: 400;
  font-size: medium;
}

.icon-styles {
  width: 20px;
  height: 15px;
  margin-left: 5px;
  margin-right: 5px;
  fill: rgba(21, 27, 37, 0.7);
}

.info-class {
  margin-bottom: 10px;
}

.main-container {
  padding: 5px;
}
