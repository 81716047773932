.search-container {
  display: flex;
  flex-direction: column;
  margin: 16px auto;
  min-width: 80%;
}

.search-option-radio {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
}

.search-input-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.search-suggestions-container {
  position: absolute;
  width: 42%;
  margin-top: 2px;
}

.search-suggestions-popup {
  max-height: 256px;
  overflow-y: auto;
  border: 0.5px solid #e8e8e9;
  border-radius: 8px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.primary-btn {
  height: 2.5rem;
  font-size: 16px;
  padding: 1rem 1rem;
  width: 12%;
}

.save-btn {
  display: flex;
  justify-content: end;
  margin: 24px 0;
}

.body-loader {
  text-align: center;
  margin: 64px;
  font-size: 18px;
}
