.body-loader {
    text-align: center;
    margin: 64px;
    font-size: 18px;
    font-weight: 600;
    font-family: "Open Sans", "Roboto", sans-serif;
}

.page-header {
    background: #F3F3F3;
    padding: 24px;
    margin: 24px 16px;
    border-radius: 8px;
}

.app-heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heading {
    color: #151B25;
    font-family: 'Montserrat';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.18px;
}

.app-detail .key {
    color: #151B25;
    font-family: "Open Sans", "Roboto", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    margin-right: 8px;
}

.app-detail .value {
    color: #151B25;
    font-family: "Open Sans", "Roboto", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
}

.sub-section {
    border-radius: 8px;
    border: 1px solid rgba(21, 27, 37, 0.50);
    padding: 16px 32px;
    margin: 16px;
}

.info-row {
    margin-left: -8px;
}

.info-row .key {
    color: rgba(21, 27, 37, 0.70);
    font-family: "Open Sans", "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.info-row .value {
    color: #151B25;
    font-family: "Open Sans", "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.table-row {
    color: rgba(21, 27, 37, 0.7);
    font-family: "Open Sans", "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    height: 56px;
}


.show-more-link {
    cursor: pointer;
    color: #5E2368;
    font-family: "Open Sans", "Roboto", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
}

.action-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 24px 16px;
}

.action-button {
    width: 180px;
    font-size: 320px;
}

.dialog-input-container {
    font-family: "Open Sans", "Roboto", sans-serif;
    margin: 16px 0;
}

.dialog-input-container label{
    font-family: "Open Sans", "Roboto", sans-serif;
    font-weight: 600;
    margin: 8px 0;
}

.cal-trigger-date {
    z-index: 30;
    display: flex;
    justify-content: space-around;
    max-width: 20%;
}

.btn-save {
    margin-left: 86%;
}