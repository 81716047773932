.api-admin-api-edit-dialog-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  min-width: 720px;
  margin: 16px;
}
.api-admin-api-edit-dialog-async {
  margin: 16px;
}
.api-edit-dialog-inputs {
  width: 348px;
}

.api-admin-api-edit-dialog-async label span,
.api-admin-api-edit-dialog-body label span {
  font-weight: 600;
}

.api-edit-input {
  margin: 12px 0;
}

.text-area-wrapper {
  margin-bottom: -12px;
}

.text-area-wrapper label[data-testid="input-system_settings-content"] {
  margin-top: 12px;
}

.api-edit-sub-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 398px;
}

.api-info-spec-actions {
  gap: 1rem;
}
.api-info-spec-actions>:first-child {
  margin-left: 16px;
}

.api-info-lower-card {
  height: 100%;
}
