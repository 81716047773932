.contract_management {
    position: relative;
}

.contract_management_navigation_buttons {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    position: static;
    top: 70px;
}

.contract-management__nav-button--home {
    background-color: white !important;
    color: #0e79cc !important;
    border-radius: 0px !important;
    font-family: "Raleway", sans-serif !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
}

.contract-management__nav-button--search-orgs {
    background-color: white !important;
    color: #0e79cc !important;
    border-radius: 0px !important;
    font-family: "Raleway", sans-serif !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
}

.contract-management__card {
    position: relative;
    top: 120px;
    margin-bottom: 150px;  
}

.contract-management__card-wrapper {
    border-radius: 0px !important;
}

.contract-management__card-content {
    padding: 0px 20px 0px 20px;
}

.contract_management_card_header {
    margin-bottom: 10px;
}

.contract-management__org-name {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.15px;
    text-align: left;
}

.contract-management__org-id {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #000000;
}

.contract-management__org-id-title {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #000000;
}

.contract-management__card-footer {
    display: flex;
    justify-content: flex-end;
    padding-right: 40px;
    padding-bottom: 30px;
}

.empty_no_rows_overlay {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-bottom: 15px;
}

.empty_state_illustration_text {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #000000;
}

.contracted-bundles_grid {
    border-bottom: 1px solid #bdc3c7;
    border-radius: 2px;
    margin-bottom: 20px;
}

.contracted-bundles_grid-content .ag-body-viewport {
    max-height: 18rem;
    overflow-y: auto !important;
    height: 13rem;
    padding-bottom: 20px;
}
.contracted-bundles-tab_grid {
    border-bottom: 1px solid #bdc3c7;
    border-radius: 2px;
    margin-bottom: 20px;
}

.contracted-bundles_tab_grid-content .ag-body-viewport {
    max-height: 30rem;
    overflow-y: auto !important;
    height: 28rem;
    padding-bottom: 20px;
}

.empty_states_image {
    height: 400px !important;
    width: 400px !important;
}

.contracted-apis_grid {
    border-bottom: 1px solid #bdc3c7;
    border-radius: 2px;
}

.contracted-apis_grid-content .ag-body-viewport {
    max-height: 18rem;
    overflow-y: auto !important;
    height: 13rem;
    padding-bottom: 20px;
}
.contracted-apis_tab_grid {
    border-bottom: 1px solid #bdc3c7;
    border-radius: 2px;
}

.contracted-apis_tab_grid-content .ag-body-viewport {
    max-height: 30rem;
    overflow-y: auto !important;
    height: 28rem;
    padding-bottom: 20px;
}

.contract-review__card-footer{
    display: flex;
    justify-content: flex-end;
    padding-right: 40px;
    padding-bottom: 30px;
    gap: 15px;
}
  
  .api-contracted-tabs [role="tab"] {
    background-color: white !important;
    width: 170px;
  }
  
  .api-contracted-tabs button[aria-selected="true"] {
    border-bottom: solid 2px rgba(128, 128, 128, 1) !important;
  }
  .apis-search-box{
    width: 720px;
  }

  .search_and_apis_button_container {
    display: flex;
    flex-direction: row;
}
.contract-review-id-title {
    font-family: Open Sans;
    width: 710px;
    gap: 16px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #000000;
    margin-bottom: 15px;
}
.contract-management__card-header{
    margin-bottom: 10px;
}

.contracted-bundles_dialog .ag-body-viewport {
    max-height: 30rem;
    overflow-y: auto !important;
    height: 28rem;
    padding-bottom: 20px;
}

.bundle-apis-contracted{
    min-width:75vw !important;
}
.contracted-bundles-dialog {
    border-radius: 1px;
    padding: 0rem !important;
    border-bottom:0rem 0rem 0px !important;
}
.contracted-dialog{
    padding: 0rem 0rem 0px !important;
}

.btnn{
    display: flex;
    flex-direction: row;
}
.contracted-bundle-name{
    border-bottom:0px !important;
}

.review_button_container {
    font-family: Open Sans;
    width: 489px;
    height: 20px;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #000000;
    font-weight: 600;
    padding: 20px 0px;
}
.body-loader{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}