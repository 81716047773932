.cdk-user-upload .mdc-card {
  padding: 20px;
}
.cdk-user-upload .DropzoneArea-dropZone-2 {
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  width: auto;
}
.cdk-user-upload .MuiGrid-root.MuiGrid-container {
  width: auto;
  margin-left: 20px;
}
.cdk-user-upload .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-8 {
  margin-top: 10px;
}
:root {
  --mdc-theme-primary: #004e9a;
  --mdc-theme-primary-light: #2a96ff;
  --mdc-theme-primary-dark: #0072e1;
  --mdc-theme-secondary: #354a90;
  --mdc-theme-secondary-light: #8395d1;
  --mdc-theme-secondary-dark: #4e68be;
  --mdc-theme-background: #fff;
  --mdc-theme-text-primary-on-primary: white;
  --mdc-theme-text-secondary-on-primary: rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-primary: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-primary: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-primary: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-primary-on-primary-light: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-primary-light: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-primary-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-primary-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-primary-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-primary-dark: white;
  --mdc-theme-text-secondary-on-primary-dark: rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-primary-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-primary-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-primary-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-primary-on-secondary: white;
  --mdc-theme-text-secondary-on-secondary: rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-secondary: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-secondary: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-secondary: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-primary-on-secondary-light: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-secondary-light: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-secondary-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-secondary-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-secondary-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-secondary-dark: white;
  --mdc-theme-text-secondary-on-secondary-dark: rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-secondary-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-secondary-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-secondary-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-primary-on-background: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-background: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-light: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-light: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-dark: white;
  --mdc-theme-text-secondary-on-dark: rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-dark: rgba(255, 255, 255, 0.5);
}
.mdc-theme--background {
  background-color: #fff;
  background-color: var(--mdc-theme-background, #fff);
}
.mdc-theme--primary {
  color: #004e9a !important;
  color: var(--mdc-theme-primary, #004e9a) !important;
}
.mdc-theme--primary-light {
  color: #2a96ff !important;
  color: var(--mdc-theme-primary-light, #2a96ff) !important;
}
.mdc-theme--primary-dark {
  color: #0072e1 !important;
  color: var(--mdc-theme-primary-dark, #0072e1) !important;
}
.mdc-theme--secondary {
  color: #354a90 !important;
  color: var(--mdc-theme-secondary, #354a90) !important;
}
.mdc-theme--secondary-light {
  color: #8395d1 !important;
  color: var(--mdc-theme-secondary-light, #8395d1) !important;
}
.mdc-theme--secondary-dark {
  color: #4e68be !important;
  color: var(--mdc-theme-secondary-dark, #4e68be) !important;
}
.mdc-theme--text-primary-on-primary {
  color: white !important;
  color: var(--mdc-theme-text-primary-on-primary, white) !important;
}
.mdc-theme--text-secondary-on-primary {
  color: rgba(255, 255, 255, 0.7) !important;
  color: var(
    --mdc-theme-text-secondary-on-primary,
    rgba(255, 255, 255, 0.7)
  ) !important;
}
.mdc-theme--text-hint-on-primary {
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(
    --mdc-theme-text-hint-on-primary,
    rgba(255, 255, 255, 0.5)
  ) !important;
}
.mdc-theme--text-disabled-on-primary {
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(
    --mdc-theme-text-disabled-on-primary,
    rgba(255, 255, 255, 0.5)
  ) !important;
}
.mdc-theme--text-icon-on-primary {
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(
    --mdc-theme-text-icon-on-primary,
    rgba(255, 255, 255, 0.5)
  ) !important;
}
.mdc-theme--text-primary-on-primary-light {
  color: rgba(0, 0, 0, 0.87) !important;
  color: var(
    --mdc-theme-text-primary-on-primary-light,
    rgba(0, 0, 0, 0.87)
  ) !important;
}
.mdc-theme--text-secondary-on-primary-light {
  color: rgba(0, 0, 0, 0.54) !important;
  color: var(
    --mdc-theme-text-secondary-on-primary-light,
    rgba(0, 0, 0, 0.54)
  ) !important;
}
.mdc-theme--text-hint-on-primary-light {
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(
    --mdc-theme-text-hint-on-primary-light,
    rgba(0, 0, 0, 0.38)
  ) !important;
}
.mdc-theme--text-disabled-on-primary-light {
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(
    --mdc-theme-text-disabled-on-primary-light,
    rgba(0, 0, 0, 0.38)
  ) !important;
}
.mdc-theme--text-icon-on-primary-light {
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(
    --mdc-theme-text-icon-on-primary-light,
    rgba(0, 0, 0, 0.38)
  ) !important;
}
.mdc-theme--text-primary-on-primary-dark {
  color: white !important;
  color: var(--mdc-theme-text-primary-on-primary-dark, white) !important;
}
.mdc-theme--text-secondary-on-primary-dark {
  color: rgba(255, 255, 255, 0.7) !important;
  color: var(
    --mdc-theme-text-secondary-on-primary-dark,
    rgba(255, 255, 255, 0.7)
  ) !important;
}
.mdc-theme--text-hint-on-primary-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(
    --mdc-theme-text-hint-on-primary-dark,
    rgba(255, 255, 255, 0.5)
  ) !important;
}
.mdc-theme--text-disabled-on-primary-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(
    --mdc-theme-text-disabled-on-primary-dark,
    rgba(255, 255, 255, 0.5)
  ) !important;
}
.mdc-theme--text-icon-on-primary-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(
    --mdc-theme-text-icon-on-primary-dark,
    rgba(255, 255, 255, 0.5)
  ) !important;
}
.mdc-theme--text-primary-on-secondary {
  color: white !important;
  color: var(--mdc-theme-text-primary-on-secondary, white) !important;
}
.mdc-theme--text-secondary-on-secondary {
  color: rgba(255, 255, 255, 0.7) !important;
  color: var(
    --mdc-theme-text-secondary-on-secondary,
    rgba(255, 255, 255, 0.7)
  ) !important;
}
.mdc-theme--text-hint-on-secondary {
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(
    --mdc-theme-text-hint-on-secondary,
    rgba(255, 255, 255, 0.5)
  ) !important;
}
.mdc-theme--text-disabled-on-secondary {
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(
    --mdc-theme-text-disabled-on-secondary,
    rgba(255, 255, 255, 0.5)
  ) !important;
}
.mdc-theme--text-icon-on-secondary {
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(
    --mdc-theme-text-icon-on-secondary,
    rgba(255, 255, 255, 0.5)
  ) !important;
}
.mdc-theme--text-primary-on-secondary-light {
  color: rgba(0, 0, 0, 0.87) !important;
  color: var(
    --mdc-theme-text-primary-on-secondary-light,
    rgba(0, 0, 0, 0.87)
  ) !important;
}
.mdc-theme--text-secondary-on-secondary-light {
  color: rgba(0, 0, 0, 0.54) !important;
  color: var(
    --mdc-theme-text-secondary-on-secondary-light,
    rgba(0, 0, 0, 0.54)
  ) !important;
}
.mdc-theme--text-hint-on-secondary-light {
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(
    --mdc-theme-text-hint-on-secondary-light,
    rgba(0, 0, 0, 0.38)
  ) !important;
}
.mdc-theme--text-disabled-on-secondary-light {
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(
    --mdc-theme-text-disabled-on-secondary-light,
    rgba(0, 0, 0, 0.38)
  ) !important;
}
.mdc-theme--text-icon-on-secondary-light {
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(
    --mdc-theme-text-icon-on-secondary-light,
    rgba(0, 0, 0, 0.38)
  ) !important;
}
.mdc-theme--text-primary-on-secondary-dark {
  color: white !important;
  color: var(--mdc-theme-text-primary-on-secondary-dark, white) !important;
}
.mdc-theme--text-secondary-on-secondary-dark {
  color: rgba(255, 255, 255, 0.7) !important;
  color: var(
    --mdc-theme-text-secondary-on-secondary-dark,
    rgba(255, 255, 255, 0.7)
  ) !important;
}
.mdc-theme--text-hint-on-secondary-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(
    --mdc-theme-text-hint-on-secondary-dark,
    rgba(255, 255, 255, 0.5)
  ) !important;
}
.mdc-theme--text-disabled-on-secondary-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(
    --mdc-theme-text-disabled-on-secondary-dark,
    rgba(255, 255, 255, 0.5)
  ) !important;
}
.mdc-theme--text-icon-on-secondary-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(
    --mdc-theme-text-icon-on-secondary-dark,
    rgba(255, 255, 255, 0.5)
  ) !important;
}
.mdc-theme--text-primary-on-background {
  color: rgba(0, 0, 0, 0.87) !important;
  color: var(
    --mdc-theme-text-primary-on-background,
    rgba(0, 0, 0, 0.87)
  ) !important;
}
.mdc-theme--text-secondary-on-background {
  color: rgba(0, 0, 0, 0.54) !important;
  color: var(
    --mdc-theme-text-secondary-on-background,
    rgba(0, 0, 0, 0.54)
  ) !important;
}
.mdc-theme--text-hint-on-background {
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(
    --mdc-theme-text-hint-on-background,
    rgba(0, 0, 0, 0.38)
  ) !important;
}
.mdc-theme--text-disabled-on-background {
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(
    --mdc-theme-text-disabled-on-background,
    rgba(0, 0, 0, 0.38)
  ) !important;
}
.mdc-theme--text-icon-on-background {
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(
    --mdc-theme-text-icon-on-background,
    rgba(0, 0, 0, 0.38)
  ) !important;
}
.mdc-theme--text-primary-on-light {
  color: rgba(0, 0, 0, 0.87) !important;
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)) !important;
}
.mdc-theme--text-secondary-on-light {
  color: rgba(0, 0, 0, 0.54) !important;
  color: var(
    --mdc-theme-text-secondary-on-light,
    rgba(0, 0, 0, 0.54)
  ) !important;
}
.mdc-theme--text-hint-on-light {
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38)) !important;
}
.mdc-theme--text-disabled-on-light {
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(
    --mdc-theme-text-disabled-on-light,
    rgba(0, 0, 0, 0.38)
  ) !important;
}
.mdc-theme--text-icon-on-light {
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(--mdc-theme-text-icon-on-light, rgba(0, 0, 0, 0.38)) !important;
}
.mdc-theme--text-primary-on-dark {
  color: white !important;
  color: var(--mdc-theme-text-primary-on-dark, white) !important;
}
.mdc-theme--text-secondary-on-dark {
  color: rgba(255, 255, 255, 0.7) !important;
  color: var(
    --mdc-theme-text-secondary-on-dark,
    rgba(255, 255, 255, 0.7)
  ) !important;
}
.mdc-theme--text-hint-on-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(
    --mdc-theme-text-hint-on-dark,
    rgba(255, 255, 255, 0.5)
  ) !important;
}
.mdc-theme--text-disabled-on-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(
    --mdc-theme-text-disabled-on-dark,
    rgba(255, 255, 255, 0.5)
  ) !important;
}
.mdc-theme--text-icon-on-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(
    --mdc-theme-text-icon-on-dark,
    rgba(255, 255, 255, 0.5)
  ) !important;
}
.mdc-theme--primary-bg {
  background-color: #004e9a !important;
  background-color: var(--mdc-theme-primary, #004e9a) !important;
}
.mdc-theme--primary-light-bg {
  background-color: #2a96ff !important;
  background-color: var(--mdc-theme-primary-light, #2a96ff) !important;
}
.mdc-theme--primary-dark-bg {
  background-color: #0072e1 !important;
  background-color: var(--mdc-theme-primary-dark, #0072e1) !important;
}
.mdc-theme--secondary-bg {
  background-color: #354a90 !important;
  background-color: var(--mdc-theme-secondary, #354a90) !important;
}
.mdc-theme--secondary-light-bg {
  background-color: #8395d1 !important;
  background-color: var(--mdc-theme-secondary-light, #8395d1) !important;
}
.mdc-theme--secondary-dark-bg {
  background-color: #4e68be !important;
  background-color: var(--mdc-theme-secondary-dark, #4e68be) !important;
}
:root {
  --mdc-typography-base: "Raleway", sans-serif;
  --mdc-typography-font-family: "Raleway", sans-serif;
  --mdc-theme-primary: #904778;
  --mdc-theme-primary-light: #904778;
  --mdc-theme-primary-dark: #904778;
  --mdc-theme-secondary: #904778;
  --mdc-theme-secondary-light: #904778;
  --mdc-theme-secondary-dark: #904778;
  --mdc-theme-background: #fff;
}
html,
body {
  font-family: "Raleway", sans-serif !important;
  font-size: 15px;
  font-weight: 400;
}
[class*="mdc-"],
[class*="mdc-button"],
[class*="mdc-card"],
[class*="mdc-select"],
[class*="mdc-text"],
[class*="mdc-typography-"] {
  font-family: "Raleway", sans-serif !important;
}
[class*="headline"],
[class*="title"] {
  font-family: "Montserrat", sans-serif !important;
}
[class*="subtitle"] {
  font-family: "Raleway", sans-serif !important;
}
.app__fortellis-accounts--admin-app .mdc-toolbar__icon {
  background-color: transparent;
}
body {
  margin: 0;
  background-color: #f5f5f5;
}
.mdc-card {
  background-color: #fff;
}
.c-layout-screen {
  min-height: calc(100vh - 64px);
  padding-top: 64px;
  width: 100vw;
  margin: 0;
}
@media screen and (min-width: 0px) and (max-width: 9999px) {
  .c-layout-screen.cdk-fluid {
    margin: 0;
  }
}
.c-layout-screen__content {
  position: relative;
  height: auto;
  min-width: 500px;
  width: 85vw;
}
@media screen and (max-width: 480px) {
  .c-layout-screen__content {
    width: 100vw;
  }
}
.c-circular-progress--overlay-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.75);
  touch-action: none;
  pointer-events: none;
  z-index: 1000;
  padding-top: 44vh;
}
.c-circular-progress--card-overlay-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.75);
  touch-action: none;
  pointer-events: none;
  z-index: 1000;
  padding-top: 6em;
}
.c-layout-screen {
  background: url(components/img/backgrounds/brand_app_bg_300__compressed.jpg)
    no-repeat fixed top left;
  background-size: initial;
}
.c-layout-screen::before {
  z-index: -1;
  position: fixed;
  top: 0;
  display: block;
  content: "a";
  height: 300px;
  width: 100%;
  background: #f70c71;
  background: -moz-linear-gradient(
    -45deg,
    #f70c71 0%,
    #9c2c70 12%,
    #6b2574 24%,
    #354a90 45%,
    #308cb0 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    #f70c71 0%,
    #9c2c70 12%,
    #6b2574 24%,
    #354a90 45%,
    #308cb0 100%
  );
  background: linear-gradient(
    135deg,
    #f70c71 0%,
    #9c2c70 12%,
    #6b2574 24%,
    #354a90 45%,
    #308cb0 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f70c71", endColorstr="#308cb0", GradientType=1 );
}
.mdc-card {
  background-color: #fff;
}
.c-layout-section__home-grid-cell--instructional {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(components/img/backgrounds/brand_app_bg_300__compressed.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.c-layout-section__home-grid-cell--instructional::before {
  opacity: 0.44;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(components/img/backgrounds/brand_app_bg_300__compressed.jpg);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
}
.c-layout-section__home-grid-cell-icon--full {
  position: absolute;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  font-size: 22vw;
  color: white;
  opacity: 0.22;
}
.c-layout-section__home-grid-cell-icon--full:first-child {
  font-size: 23vw;
  opacity: 0.44;
}
.c-layout-section__home-grid-cell-icon--full:nth-of-type(2) {
  font-size: 6vw;
  opacity: 0.77;
}
.c-layout-section__home-grid-cell-icon--full:last-child {
  font-size: 33vw;
  opacity: 0.22;
}
.c-layout-section__home-grid-cell--navigational .mdc-list-item,
.c-layout-section__home-grid-cell--navigational
  .mdc-list-item
  .mdc-list-item__graphic {
  cursor: pointer;
  color: #0e79cc;
}
.c-layout-section__home-grid-cell--navigational .mdc-list-item:hover,
.c-layout-section__home-grid-cell--navigational
  .mdc-list-item:hover
  .mdc-list-item__graphic {
  color: #0e79cc;
}
.c-card-pending-users {
  margin-bottom: 1.5em;
}
.c-card-pending-users__info-icon {
  margin-right: 0.5rem;
  vertical-align: middle;
  opacity: 0.3;
}
.c-card-pending-users__button-icon {
  margin-right: 0.2em;
  vertical-align: inherit;
}
.c-card-pending-users__fab-button {
  position: absolute;
  right: 20px;
}
.c-card-pending-user__note-wrapper {
  position: relative;
}
.c-card-pending-users-address {
  margin-left: 31.5px;
}
.c-pending-user-note__button .mdc-button__icon,
.c-pending-user-note__button-icon {
  line-height: inherit;
}
.c-layout-section__dash-grid {
  background-color: transparent;
  margin-bottom: 32px;
}
.c-layout-section__dash.max-width {
  max-width: 1280px;
}
.c-dash-chart.spaced {
  margin-bottom: 25px;
}
.c-dash-chart__centered-card-text-wrapper {
  text-align: center;
}
.c-dash-chart__number-displayed--large {
  text-align: center;
  font-size: 5rem;
  color: #9c2c70;
  text-shadow: rgba(0, 0, 0, 0.33) 5px 5px 5px;
}
.c-dash-chart__number-displayed--medium {
  text-align: center;
  color: #9c2c70;
  text-shadow: rgba(0, 0, 0, 0.33) 5px 5px 5px;
  font-size: 4rem;
  margin: 0.52em 0em;
}
.c-dash-chart__subtitle-displayed--small {
  font-size: 0.95rem;
  color: #000000;
  color: var(--mdc-theme-text-primary-on-primary-light);
}
.c-dash-chart__inline-card-text-wrapper {
  display: inline-block;
  margin: 0.25em 2em;
  text-align: center;
}
.c-form__section-header {
  font-size: 1.25rem;
  margin-top: 2em;
  font-weight: 600;
}
.c-form__section-fields {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  padding: 0em 1em;
  border: 1px solid #aaa;
}
.c-entity-indicator__badge {
  color: white;
}
.c-entity-indicator__badge--individual {
  background-color: green;
}
.c-entity-indicator__badge--org {
  background-color: purple;
}
.c-search-input--field {
  height: 40px;
}
.c-search-input--type {
  margin-top: 4px;
  line-height: 1em;
}
.c-search-input-subman-field {
  height: 55px;
}
.c-search-input-subman-type {
  margin-bottom: 9px;
  line-height: 1em;
}
.c-search--submit {
  height: 40px;
  margin-top: 8px;
  min-width: 200px;
}
.mdc-list-item__text {
  width: 100%;
}
.c-entity-search--container {
  max-height: 400px;
  overflow: scroll;
  padding: 15px;
  background-color: #efefef;
}
.c-entity-search--result {
  padding: 1em;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  margin-bottom: 5px;
  background-color: #fff;
}
.user-activation .c-entity-search--result:hover {
  cursor: pointer;
}
.user-activation .c-entity-search--result--selected {
  background-color: transparent;
}
.c-entity-search--result-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.c-entity-search--result-title > h2 {
  margin: 0px;
  color: #904778;
}
.c-entity-search--result-title > i {
  font-size: 16px;
}
.c-entity-search--result-title > i:hover {
  cursor: pointer;
}
.c-entity-search--result-items {
  list-style: none;
  padding-left: 10px;
  display: block;
  margin-bottom: 0px;
}
.c-search-list-item__title {
  display: inline-block;
  font-weight: 700;
  min-width: 110px;
}
.c-search-list-item__divider {
  font-weight: 700;
  margin-right: 10px;
}
.c-search-list-item__text {
  display: inline-block;
}
.hint {
  font-size: 12px;
  color: #555;
  font-style: italic;
}
.c-imitation-helpertext {
  margin: 0px 0px 6px 2px;
  color: rgba(0, 0, 0, 0.6);
}
.entity-management .mdc-text-field__input {
  padding-left: 2px !important;
  border-bottom: 1px solid !important;
}
.entity-management .mdc-text-field__icon {
  top: 12px;
  right: 6px !important;
}
.mdc-input-invalid .mdc-text-field__input {
  border-color: #d50000 !important;
}
.c-address-inline-item {
  width: 24%;
}
.c-address-inline-item.select {
  margin-top: 12px;
}
.c-geo-inline-item {
  width: 49%;
}
.c-entities-approval-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.c-entities-metrics-title {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
}
.c-action-button {
  margin-right: 15px;
}
.c-field-with-icon {
  display: flex;
  margin: 6px 0px;
}
.c-field-with-icon__value {
  margin: 3px 0px 0px 10px;
}
.empty-state {
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: #888;
}
.c-dash-card.c-form-subscriptions .cdk-data-table__toolbar {
  min-height: auto;
}
.c-dash-card.c-form-subscriptions
  .cdk-data-table__toolbar
  .cdk-data-table__toolbar__title--primary {
  font-size: 0.8rem;
}
.c-entity-type__selection {
  width: 100%;
}
.cdk-expansion-panel.cdk-expansion-panel--expanded {
  margin: 1rem 0;
}
.cdk-expansion-panel.c-form-subscriptions__create-panel {
  margin-top: 0;
  margin-bottom: 1rem;
}
.cdk-expansion-panel.c-form-subscriptions__create-panel {
  background-color: #fff;
  outline: 1px solid green;
  outline: 1px solid rgba(14, 121, 204, 0.44);
}
.cdk-expansion-panel.c-form-subscriptions__create-panel
  .cdk-expansion-panel__header {
  background-color: #deffde;
  background-color: rgba(14, 121, 204, 0.08);
}
.cdk-expansion-panel.c-form-subscriptions__create-panel
  .cdk-expansion-panel__header:hover {
  background-color: #ccffcc;
  background-color: rgba(14, 121, 204, 0.1);
}
.cdk-expansion-panel--expanded.c-form-subscriptions__create-panel
  .cdk-expansion-panel__header {
  background-color: #deffde;
  background-color: transparent;
  background-color: rgba(14, 121, 204, 0.1);
}
.cdk-expansion-panel--expanded.c-form-subscriptions__create-panel
  .cdk-expansion-panel__header:hover {
  background-color: #deffde;
  background-color: transparent;
  background-color: rgba(14, 121, 204, 0.1);
}
.header-subtitle {
  padding: 0 1em;
}
.c-icon__subscription-listitem,
.c-icon__connection-listitem {
  margin-right: 0.25em;
  font-size: 1.5em;
  vertical-align: middle;
  opacity: 0.66;
}
.c-icon__subscription-certification-info-icon {
  color: orange;
  margin-right: 0.25em;
  font-size: 1.5em;
  vertical-align: middle;
  opacity: 0.66;
}
.c-icon__subscription-certification_text{
  margin-right: 0.25em;
  font-size: 1.5em;
  vertical-align: middle;
  opacity: 0.66;
  color: orange !important;
}
.c-provider-select--enabled {
  opacity: 1;
}
.c-provider-select--disabled {
  opacity: 1;
}
.c-status-indicator,
.c-status-indicator [data-status],
.c-status-indicator [class^="cdkicon-"] {
  font-size: 0.8rem;
  text-transform: uppercase;
  vertical-align: text-top;
  padding-right: 0.15em;
}
.c-status-indicator-double-stack {
  margin: 0.5rem 0;
}
.c-status-indicator[data-status="active"],
.c-status-indicator--active {
  color: green;
}
.c-status-indicator[data-status="inactive"],
.c-status-indicator--inactive {
  color: gray;
}
.c-status-indicator[data-status="pending"],
.c-status-indicator--pending {
  color: orange;
}
.c-status-indicator[data-status="declined"],
.c-status-indicator--declined {
  color: red;
}
/* SUBSCRIPTIONS STATES: */
/* CONNECTIONS: */
/* ================================================== */
/* COMPONENT: BULK SUBSCRIPTIONS */
/* ================================================== */
.cdk-bulksubs__card {
  position: relative;
}
.cdk-bulksubs__button {
  width: 100%;
  margin: 0 0.5rem !important;
}
.cdk-bulk-subs__list {
  background-color: white;
  margin-top: 6px;
}
.cdk-provider__select {
  min-width: 300px;
  max-width: 300px;
}
.cdk-async-provider__select {
  min-width: 300px;
  max-width: 300px;
}
.cdk-bulksubs__input {
  margin-bottom: 1rem;
}
.cdk-bulksubs-card__text {
  margin: 16px 0px 0px;
  font-size: 1rem;
}
.cdk-bulksubs-card__text-sub {
  margin: 2px 0 16px 0;
  font-size: 0.85rem;
  color: rgba(0, 0, 0, 0.6);
}
.cdk-bulksubs-card__api-line {
  margin: 0;
}
.cdk-bulksubs-card__api-line-indicator {
  margin-left: 0.5rem;
}
.file-upload__error-message {
  color: #d50000;
  margin: 8px 0px 0px;
  font-size: 0.85rem;
}
.cdk-bulksubs__loading-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cdk-bulksubs__solution-info-header {
  background-color: #666;
  padding: 0.5em 1em;
  position: relative;
  border-radius: 4px 4px 0px 0px;
  color: white;
  margin-top: 16px;
}
.cdk-bulksubs__solution-info-header:before {
  content: "App";
  position: absolute;
  color: white;
  top: 10px;
}
.cdk-bulksubs__solution-info-header > h2 {
  margin: 1.25em 0em 0em;
}
.cdk-bulksubs__solution-info-header > p {
  margin: 0.5em 0em;
}
.cdk-bulksubs__solution-info {
  background-color: #f1f1f1;
  padding: 0.5em 1em 1.5em;
  border-radius: 0px 0px 4px 4px;
}
.cdk-bulksubs__solution-info .mdc-form-field {
  margin-right: 1em;
}
.cdk-bulksubs__output {
  width: 100%;
  white-space: pre;
  overflow: auto;
  min-height: 50px;
  height: auto;
  max-height: 500px;
  color: white;
  background-color: #222;
  font-size: 0.9rem;
  padding: 1em;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  font-family: monospace;
}
.cdk-bulksubs__output .error-text {
  color: #ff4040;
  font-weight: bold;
}
.cdk-bulksubs__text-field-error {
  color: #d50000 !important;
}
.fdn-provider-text-field {
  width: 100%;
}
.fdn-typeahead {
  width: 100%;
  position: relative;
}
.fdn-typeahead__options {
  z-index: 99;
  position: absolute;
  top: calc(100% - 25px);
  width: 100%;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  list-style: none;
  padding: 0;
  margin: 0;
}
.fdn-typeahead__options > li {
  padding: 1em;
  background-color: #fff;
}

.fdn-typeahead__options > li:hover {
  cursor: pointer;
  background-color: #eee;
}
.fdn-typeahead__options > li.selected {
  background-color: #eee;
}
/* ================================================== */
/* COMPONENT: API METRICS */
/* ================================================== */
.loading-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.loading-container.small {
  height: 50px;
}
.small-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.c-datatable-wrapper {
  padding: 1em;
  margin: 1em 0em;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #efefef;
}
.c-pagination-actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0em 0em;
}
.cdk-data-table tr:hover {
  background-color: #f6f6f6 !important;
}

.cdk-data-table {
  white-space: normal;
}

.c-api-provider-table {
  table-layout: fixed;
}

.api-provider__column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.api-provider__column:hover {
  white-space: normal;
  overflow: visible;
  word-break: break-all;
}

.table-with-pagination__column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-with-pagination__column:hover {
  white-space: normal;
  overflow: visible;
  word-break: break-all;
}

.table-with-pagination__clickable-column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-with-pagination__clickable-column:hover {
  white-space: normal;
  overflow: visible;
  word-break: break-all;
  cursor: pointer;
}

.column-1 {
  min-width: 20% !important;
  max-width: 20% !important;
  text-overflow: ellipsis;
}

.column-2 {
  min-width: 15% !important;
  max-width: 30em;
}

.column-3 {
  min-width: 15% !important;
  max-width: 15% !important;
  text-overflow: ellipsis;
}

.column-4 {
  min-width: 10% !important;
  max-width: 10% !important;
  text-overflow: ellipsis;
}

.column-5 {
  min-width: 10% !important;
  max-width: 10% !important;
  text-overflow: ellipsis;
}

.column-6 {
  min-width: 10% !important;
  max-width: 10% !important;
  text-overflow: ellipsis;
}

.column-7 {
  min-width: 10% !important;
  max-width: 10% !important;
  text-overflow: ellipsis;
}

.page-count {
  color: #000;
  font-size: 1rem;
}
.inline-page-count {
  vertical-align: middle;
  padding-left: 1em;
}

.filter-hint {
  margin: 0em 0em 0.5em 1em;
  color: #444;
}
.filter-bar {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}
.filter-bar .mdc-chip--selected {
  background-color: #9c2c70;
  color: #fff;
  font-weight: 600;
}
.filter-bar .mdc-chip--selected:hover {
  background-color: #904778;
  color: #fff;
}
.filter-chips-container {
  display: flex;
}
.filter-chips {
  margin-right: 25px;
  /*
  display: flex;
  flex-wrap: wrap;
  max-width: 24em;
   */
}
.filter-searchbar .filter-hint {
  margin-left: 0;
}
.filter-searchbar .mdc-text-field--with-trailing-icon .mdc-text-field__input {
  padding-left: 1em;
  padding-right: 4em;
}
.filter-searchbar input {
  padding: 1em 4em 0em 0.5em;
  border-radius: 6px;
  border: 1px solid #bbb;
  background-color: #f5f5f5;
  font-size: 15px;
  width: 275px;
}
.filter-searchbar .mdc-floating-label--float-above {
  cursor: auto;
  margin-left: 6px;
  margin-top: 2px;
}
.filter-searchbar .mdc-text-field__icon--trailing {
  top: 13px;
}
.filter-searchbar .rmwc-icon--ligature {
  top: 16px;
  font-size: 16px;
}

.filter-searchbar-left {
  margin-right: 0;
  padding-left: 80% !important;
}
.filter-searchbar-left
  .mdc-text-field--with-trailing-icon
  .mdc-text-field__input {
  padding-left: 1em;
  padding-right: 4em;
}
.filter-searchbar-left input {
  padding: 1em 4em 0em 0.5em;
  border-radius: 6px;
  border: 1px solid #bbb;
  background-color: #f5f5f5;
  font-size: 15px;
  width: 175px;
}
.filter-searchbar-left .mdc-floating-label--float-above {
  cursor: auto;
  margin-left: 6px;
  margin-top: 2px;
}
.filter-searchbar-left .mdc-text-field__icon--trailing {
  top: 13px;
}
.filter-searchbar-left .rmwc-icon--ligature {
  top: 16px;
  font-size: 16px;
}

.date-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 360px;
  height: 75px;
  margin-top: 16px;
  margin-right: 8px;
}
.date-bar .filter-date-range {
  width: 220px;
  height: 75px;
  margin-right: 8px;
  z-index: 1;
}
.date-bar .filter-date-range span {
  bottom: 13px !important;
}
.date-bar .cdk-date-range-picker--dialog-wrapper {
  float: right;
}
.date-bar .DatePickerInput__leading-icon {
  position: absolute;
  display: inline-block;
  padding: 28px 16px 0 0;
  color: rgba(0, 0, 0, 0.6);
}
.date-bar .DatePickerInput__wrapper {
  display: inline-block;
  padding-left: 40px;
  padding-top: 13px;
  align-items: center;
}
.date-bar .cdk-date-picker {
  cursor: pointer;
  background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%230%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
  background-repeat: no-repeat;
  background-position: right 4px top 7px;
}
.cdk-bulksubs__card .cdk-date-picker--container {
  margin-top: 12px;
}
.cdk-bulksubs__card .DatePickerInput__leading-icon {
  position: absolute;
  display: inline-block;
  padding: 20px 16px 0 0;
  color: rgba(0, 0, 0, 0.6);
}
.cdk-bulksubs__card .DatePickerInput__wrapper {
  display: inline-block;
  padding-left: 40px;
  align-items: center;
}
.cdk-bulksubs__card .cdk-date-picker {
  cursor: pointer;
  background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%230%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
  background-repeat: no-repeat;
  background-position: right 4px top 12px;
}
.api-metrics-cards {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
.api-metrics-card {
  background-color: white;
  border-radius: 4px;
  padding: 2em;
  height: 120px;
  text-align: center;
  width: 25%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.api-metrics-card__title {
  margin-bottom: 10px;
}
.api-metrics-card__content {
  color: #9c2c70;
  font-weight: 700;
  font-size: 36px;
}
.app-metrics-cards {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
.app-metrics-card {
  background-color: white;
  border-radius: 4px;
  padding: 2em;
  height: 120px;
  text-align: center;
  width: 25%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.hint-text-metrics {
  margin: 0;
  color: #666;
  font-style: italic;
  font-size: 0.85rem;
}
.minHeight-40 {
  min-height: 40px;
}
.minHeight-80 {
  min-height: 80px;
}
.margin-sides-5 {
  margin-right: 5px;
  margin-left: 5px;
}
.visibility--hidden {
  visibility: hidden;
  font-size: 0;
}
.visibility--visible {
  visibility: visible;
  font-size: inherit;
}
[class^="cdkicon-"],
.material-icons,
.mdc-fab__icon,
.mdc-fab__icon.material-icons {
  font-family: "CDK-Icons" !important;
}
.loadingButton-leftPadding {
  margin-left: 8px;
}
.org-management-title {
  float: left;
}
.subscriptions-link-wrapper {
  float: right;
  padding-top: 5px;
  padding-right: 5px;
}
a.subscriptions-link {
  cursor: pointer;
  color: purple;
}
.cdk-status-indicator--inline__text {
  display: flex;
}
.fortellis-developer-terms {
  color: rgba(0, 0, 0, 0.87) !important;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-left: 16px;
  margin-top: 20px;
  margin-bottom: 8px;
}
.fortellis-developer-terms-subtitle {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-family: Raleway;
  font-weight: 600;
  line-height: 24px;
  margin-left: 16px;
  margin-bottom: 30px;
}
.current-terms-title {
  color: rgba(0, 0, 0, 0.87);
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 7px;
}
.update-info {
  color: rgba(0, 0, 0, 0.6);
  font-family: Raleway;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1.25px;
  line-height: 14px;
  margin-top: 17px;
  padding-left: 32px;
}
.tncEditor-editor {
  height: auto;
  padding: 0.4em;
  margin-top: 1em;
  overflow: scroll;
}
.tncEditor-controls span {
  padding: 0px 5px 0px 5px;
  margin: 0.4em !important;
}
.tncEditor-controls {
  display: inline-flex;
  align-items: center;
}
.update-fortellis-tnc_card_header {
  padding: 24px;
}
.update-fortellis-tnc_card_title {
  height: 40px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}
.update-fortellis-tnc_card_subTitle {
  height: 40px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
}
.update-fortellis-tnc_toolbar {
  background-color: #f1f1f1;
  font-weight: bold;

  padding: 8px;
}
.minHeight-264 {
  min-height: 310px;
}
.minHeight-864 {
  min-height: 864px;
}
.update-fortellis-tnc_checkbox_div {
  float: right;
  color: rgba(0, 0, 0, 0.6);
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
}
.update-fortellis-tnc_style_button {
  cursor: pointer;
}
.update-fortellis-tnc_style_button:hover {
  background-color: #e6e6e6;
}
.update-fortellis-tnc_style_button_active {
  cursor: pointer;
  background-color: #d8d8d8;
}
.update-fortellis-tnc_style_icons {
  vertical-align: middle;
}
.update-fortellis-tnc_button {
  float: right;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);
  margin: 0px 20px 0px 20px;
}
.update-fortellis-tnc_card_text_section {
  padding: 0px;
}
.version-number {
  margin-left: 6px;
  width: 5%;
}
.signed-up-users-page-heading-card {
  margin-bottom: 1.5em;
  text-align: center;
}
.signed-up-users-page-title {
  color: rgba(0, 0, 0, 0.87) !important;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-left: 16px;
  margin-bottom: 8px;
}
.signed-up-users-page-subtitle {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-family: Raleway;
  font-weight: 600;
  line-height: 24px;
  margin-left: 16px;
}
.cdk-api-usage__action-icon {
  margin-right: 1rem;
}
.cdk-api-usage__action-icon:hover {
  cursor: pointer;
}
.cdk-api-usage__spinner-wrapper {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cdk-api-usage__spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cdk-api-usage__spinner-text {
  font-weight: 500;
  margin-bottom: 0.75rem;
}
.cdk-api-usage__spinner-text--error {
  color: #cc0000;
}
.cdk-api-usage__data-table tr:hover {
  background-color: transparent !important;
}
.cdk-api-usage__select {
  margin-left: 0.5rem;
  height: auto;
}
.cdk-api-usage__select.mdc-select__native-control {
  padding-top: 0;
  padding-bottom: 0;
}
.cdk-api-usage__title-primary {
  display: flex;
  align-items: center;
}
.resend-email-button-text {
  color: #904778 !important;
  font-size: 14px;
  font-family: Raleway;
  font-weight: 600;
  letter-spacing: 1.25px;
  text-align: center;
}
.resend-email-button {
  height: 36px;
  width: 260px;
  border: 2px solid #904778;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0);
  margin-top: 70px;
}
.terms_and_conditions-dialog .close-icon {
  color: #aaa;
  float: right;
}
.terms_and_conditions-dialog .close-icon:hover {
  cursor: pointer;
}
.terms_and_conditions-dialog .mdc-dialog__surface {
  margin: 1em 0em;
  max-height: calc(100% - 2em);
}
.terms_and_conditions-dialog
  .mdc-dialog__surface
  .mdc-dialog__body--scrollable {
  min-height: 200px;
  max-height: 500px;
}
.terms_and_conditions-dialog-errorText {
  text-align: center;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.terms_and_conditions-dialog-updateOn {
  width: 512px;
  margin-top: 4px;
  margin-bottom: 2px;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}
.add-new-entity-button {
  height: auto;
  width: 100%;
  max-width: 320px;
  border: 2px solid #904778;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0);
  margin-top: 30px;
}
.add-user-to-entity-button {
  height: 36px;
  width: 320px;
  border: 2px solid #904778;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0);
  margin-top: 20px;
}
.table-list {
  width: 100%;
}
.table-cell-20 {
  text-align: left !important;
}
.user-entity-action-button {
  height: 36px;
  border: 2px solid #904778;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0);
}
.make-admin-entity-button {
  width: 140px;
  margin-right: 0.5em;
}
.remove-entity-button {
  width: 100px;
}
.user-status {
  color: rgba(0, 0, 0, 0.6);
  font-family: Raleway;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1.25px;
  line-height: 14px;
  padding-left: 48px;
}
.user-status-color {
  font-size: 0.8rem;
}
/* ================ User Search ================ */
.users-heading {
  line-height: 2.5rem;
}
.user-search-field-container .mdc-text-field.persistent-search-box {
  height: 36px;
  width: 250px;
}
.user-search-field-container
  .mdc-text-field.persistent-search-box
  .mdc-text-field__input {
  padding: 0 16px 0 16px;
}
/* ================ eo User Search ================ */
/* ================ User Profile ================ */
.user-profile-actions-container .mdc-button:not(:disabled) {
  color: #904778;
}
.user-profile-actions {
  font-size: 14px;
  font-family: Raleway;
  font-weight: 600;
  letter-spacing: 1.25px;
  text-align: center;
}

.suspend-user-button {
  margin-bottom: 30px;
}
.user-needs-to-be-approved-heading {
  font-size: 1rem;
}
/* ================ eo User Profile ================ */

.mdc-dialog__body.api-provider-dialog-subheader {
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
  margin-top: 0;
  padding-bottom: 0;
}

.pricing_plan_title {
  font-weight: bold;
  margin-bottom: 15px;
}

.pricing_no_plans {
  background: white;
  padding: 4%;
}

.pricing_plan_card {
  margin-bottom: 25px;
}

.pricing_plan_card_header {
  text-align: center;
}

/* ================ Spec preview ================ */
.mdc-button--primary:not(:disabled) {
  /*
   * the default of 500 just doesn't look quite right
   * with white text on dark blue background
   */
  font-weight: 600;
}

.spec-button-column {
  width: 183px;
}

.spec-label-column {
  width: 155px;
}

.spec-info-spec-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.spec-info-spec-header .mdc-card__title--large {
  padding-top: 0;
}

.spec-info-spec-header .spec-info-fragment {
  display: flex;
  flex-direction: row;
  justify-content: normal;
}

.spec-info-spec-header .spec-info-title-fragment {
  align-items: center;
}

.spec-info-spec-header .spec-info-api-label-fragment {
  align-items: baseline;
}

.spec-info-title-progress {
  padding-left: 1em;
}

.spec-info-label-progress {
  padding-right: 1em;
}

.spec-info-notes-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spec-info-spec-body {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  max-height: 50em;
  overflow-y: scroll;
}

.api-info-spec-body {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  max-height: 30em;
  overflow-y: scroll;
}

.api-info-env-version-select {
  width: 112px;
}
.api-info-env-version-select select {
  width: 112px;
}

.api-info-notes-actions {
  margin-top: 0.5rem;
}

/*
 overflow-wrap: anywhere looks a lot nicer than break-word,
 but is only supported on some very new browsers
 */
@supports not (overflow-wrap: anywhere) {
  .spec-info-notes-body {
    overflow-wrap: break-word;
  }
  .spec-info-meta-body {
    overflow-wrap: break-word;
  }
}
@supports (overflow-wrap: anywhere) {
  .spec-info-notes-body {
    overflow-wrap: anywhere;
  }
  .spec-info-meta-body {
    overflow-wrap: anywhere;
  }
}

.spec-info-status-indicator {
  margin-left: 1em;
  align-items: baseline;
}

.spec-info-status-indicator .cdk-status-indicator--inline__text {
  display: flex;
  font-weight: bold;
  font-family: "Raleway", sans-serif;
  padding-top: 4px;
}

.spec-preview-lines {
  margin-left: 4em;
  border-left: 1px solid #808080;
}

.spec-preview-line {
  position: relative;
}

.spec-preview-line:hover {
  background-color: rgba(128, 128, 128, 0.2);
}

.spec-preview-gutter {
  position: absolute;
  left: -4em;
  user-select: none;
}

.spec-preview-line-number {
  box-sizing: border-box;
  left: 0;
  width: 4em;
  padding-right: 0.5em;
  text-align: right;
}

.spec-preview-line-content {
  box-sizing: border-box;
  margin: 0;
  padding-left: 0.75em;
}

/* ================ Misc ================ */

.status-icon-button {
  display: flex;
  flex-wrap: nowrap;
  word-break: keep-all;
}

.cdk-bulksubs__card .DayPicker {
  /* I don't know why the DatePicker calendar is position: relative
     in defiance of what the pack-in SCSS says it should be, but
     somehow it is -- this fixes that */
  position: absolute;
  z-index: 1000000;
}

/* fixes status text being off-centre from icons */
.status-icon-button .cdk-status-indicator--inline__text {
  margin-top: 3px;
  font-family: "Raleway", sans-serif;
}

.DayPickerNavigation_button__horizontal svg {
  display: inline-block;
}

.cdk-data-table__cell--non-numeric.cdk-data-table__cell--non-numeric {
  text-align: center;
}
.link-item {
  margin-bottom: 4px;
  cursor: pointer;
  padding: 8px 16px;
}
.link-item:hover {
  background-color: #f3f3f3;
}
.link-description {
  overflow: hidden;
}

.input-helper-text {
  max-width: 600px;
  margin-left: 24px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}

.bulk-subscription-input-helper-text {
  max-width: 600px;
  display: flex;
}

.bulk-subscription-app-certification-text {
  max-width: 600px;
  display: flex;
  color: #000;
}

.sol-search-container {
  width: 800px;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.sub-search-container {
  display: flex;
  flex-direction: row-reverse;
  gap: 16px;
}

.s-result {
  width: 600px;
  position: absolute;
  background-color: white;
  z-index: 40;
  max-height: 200px;
  overflow-x: auto;
  padding: 8px 0px;
  margin: auto;
  opacity: 1;
  border-radius: 4px;
  transition: opacity 211ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 141ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

@media (max-width: 720px) {
  .s-result {
    max-width: calc(100% - 32px);
  }
}

.MuiAutocomplete-input.MuiAutocomplete-inputFocused.mdc-text-field.mdc-text-field--filled {
  width: 100%;
}

.manage_agreements-dialog .close-icon {
  color: #aaa;
  float: right;
}
.manage_agreements-dialog .close-icon:hover {
  cursor: pointer;
}
.manage_agreements-dialog .mdc-dialog__surface {
  margin: 1em 0em;
  max-height: calc(100% - 2em);
}
.manage_agreements-dialog .mdc-dialog__surface .mdc-dialog__body--scrollable {
  min-height: 200px;
  max-height: 500px;
}

.manage_agreements-dialog-org-name {
  text-align: center;
  margin-top: 15px;
}

.manage_agreements-dialog-org-name > h2 {
  margin: 0px;
  color: #904778;
}

.manage_agreements-dialog .org-id {
  font-weight: 700;
  text-align: center;
}

.manage_agreements-dialog-type > h3 {
  margin-top: 0px;
  margin-bottom: 10px;
}

.manage_agreements-dialog-button {
  height: auto;
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  border: 2px solid #904778;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0);
  color: #904778 !important;
  font-size: 14px;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  letter-spacing: 1.25px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.admin_org_switcher-dialog-button {
  height: auto;
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  border: 2px solid #904778;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0);
  color: #904778 !important;
  font-size: 14px;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  letter-spacing: 1.25px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.manage_contracted_apis-button{
  height: auto;
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  border: 2px solid #904778;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0);
  color: #904778 !important;
  font-size: 14px;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  letter-spacing: 1.25px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.manage_flags-dialog-button {
  height: auto;
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  border: 2px solid #904778;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0);
  color: #904778 !important;
  font-size: 14px;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  letter-spacing: 1.25px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.org_search_delete_button {
  color: #904778 !important;
  margin-left: 70px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.manage-agreement-list-item {
  margin-top: 15px;
}

.manage-agreement-truncate-filename {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
  text-decoration-color: #904778 !important;
  color: #904778 !important;
}

.manage-agreement-uploadfile {
  margin-left: 3px;
  margin-bottom: 10px;
}

.manage-agreement-upload__title {
  display: inline-block;
  font-weight: 500;
  min-width: 110px;
}

.manage-feature-gates-text-area .mdc-text-field__input {
  font-family: monospace !important;
}

.manage-feature-gates-error {
  color: #bb0000;
  padding-right: 1em;
}

.api-edit-system-settings-text-area {
  width: 500px;
  height: 160px;
}

textarea[name="api-edit-system-settings-text-area"] {
  resize: none;
}

.bulkSubscriptionPageHeader {
  margin-bottom: 5px;
}

.content-flex {
  display: flex;
  flex-direction: column;
}

.sm-api-type-subtitle {
  font-size: 10px;
  line-height: 10px;
  margin: 0px;
  width: auto;
}

.filter-title {
  font-size: 16px;
}

.app-name-expansion-panel {
  display: flex;
  align-items: center;
  gap: 8px;
}

.app-name-expansion-panel span.cdk-tooltip {
  z-index: 1;
}

.dealer-pricing-tooltip {
  width: 156px;
}

.subscription-management-dropdown {
  margin-bottom: 9px;
  line-height: 1em;
  text-align-last: center;
  width: 20%;
  margin-top: 21px;
  padding-top: 0% !important;
  padding-bottom: 0% !important;
}

.bulk-subscription-dropdown {
  margin-bottom: 9px;
  line-height: 1em;
  text-align-last: center;
  width: 120%;
  margin-top: 21px;
  padding-top: 0% !important;
  padding-bottom: 0% !important;
}

.bulk-subscription-input-field {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 2rem;
}
.tooltip {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  visibility: hidden;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  width: fit-content;
  margin-left: 40px;
  align-items: center;
}
.cdk-bulksubs-card__requiresDMS {
  vertical-align: 10px;
  padding-left: 10px;
  font-weight: 600;
}
.subscription-mgmt-api-activation-error-modal {
  max-height: 900px;
  overflow-y: scroll;
  height: 500px;
}
.subscription-mgmt-api-activation-error-modal-heading {
  text-align: center;
  font-size: 1.1em;
}
.cdk-expansion-panel__header {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 56px !important;
  padding: 0 24px 24px !important;
  transition: height 225ms;
}
.cdk-navigation-button {
  margin-left: 1rem;
}
.bulk_subs_btn{
  width: 98%;
}
.bulk_sub_tooltip{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: rgb(255, 255, 255);
  padding: 13px;
  border-radius: 6px;
  white-space: nowrap;
  z-index: 0;
  bottom: 47px;
  font-size: 19px;
}
.sub_tooltip{
  position: absolute;
  left: 85%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: rgb(255, 255, 255);
  padding: 8px;
  border-radius: 6px;
  white-space: nowrap;
  z-index: 0;
  bottom: 18%;
}
