.certification-status-chip {
  font-size: 18px;
  margin-bottom: 16px;
  padding: 8px;
}

.no-listing-fee-head {
  margin-top: -20px;
  margin-bottom: 4px;
  font-size: 14px;
}

.status-card-chips-div {
  margin: 8px;
}

.table-container-certfication>div>div>div:nth-child(2)>div {
  height: 408px !important
}

.table-container-certfication>div {
  height: 481px !important;
}

.table-header-certification {
  display: flex;
  margin: 16px;
}

.table-header-certification>div {
  width: 900px !important;
}

.table-header-certification>button {
  margin-left: 16px;
}
.certification_view_tooltip{
  transform: translateX(50%);
}
.save-button-tooltip{
  display: flex;
  justify-content: flex-end;
}