@use '@material/ripple/mixins' as mdc-ripple-mixins;
// @use '@rmwc/data-table/data-table.css';

.fdn-card__primary-content {
  @include mdc-ripple-mixins.states(transparent);
  padding: 1em;
  position: relative;
}

.fdn-split-flex {
  display: flex;
  justify-content: space-between;
}

.fdn-metric {
  display: flex;
  flex-direction: column;

  &__note.mdc-typography--caption {
    color: rgba(0, 0, 0, 0.6);
    margin-top: 16px;
  }

  &__content {
    position: relative;
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 6;
    display: flex;
    align-items: center;
    padding-top: 300px;
    box-sizing: border-box;
    flex-direction: column;

    &.empty {
      padding-top: 65px;
    }
  }

  &--flex-container {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &--header {
    margin: 0px;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
  }

  &--content {
    font-weight: 600;
    margin: 0;
    font-size: 18px;
  }

  &--divider {
    height: 48px;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.25);
    margin: 0px 16px;
  }

  &.large {
    .fdn-metric {
      &--header {
        font-size: 16px;
      }

      &--content {
        font-size: 26px;
      }
    }
  }

  &--empty-space {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 20%;
    box-sizing: border-box;
    text-align: center;
  }

  &--refresh {
    margin-top: 16px;
  }

  &__table {
    word-wrap: break-word;
    &-col-1 {
      // Percentages aren't working on the material table so picking a value
      width: 20em;
      min-width: 15em;
      max-width: 20em;
    }
    &-col-2, &-col-3, &-col-4 {
      width: 15em;
      min-width: 15em;
      max-width: 15em;
    }
  }
}

.fdn-card__implementation-metrics {
  margin-bottom: 16px;
}

.usage-back-button-position {
  position: absolute;
  left: 5px;
  top: 80px;
}

.org-search-bar{
  display: flex;
  // margin-top: 250px;
  .org-input-search-bar{
    padding-right: 20px;
    width: 90%;
  }
}

.subscriptions-data-page-title {
  height: 40px;
  font-size: 30px;
  letter-spacing: 0.25px;
  line-height: 40px;
  margin-bottom: 25px;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
}

.subscription-search-radio-buttons {
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}

.subscription-search-bar {
  display: grid;
  grid-template-columns: 8fr 2fr;
  grid-gap: 10px;
  -ms-grid-columns: 8fr 2fr;
  margin-bottom: 20px;
}

.search-subscriptions {
  max-width: 70%;
  margin: auto;
}

.search-results-container {
  position: absolute;
  width: 58%;
  display: grid;
  grid-template-columns: 16fr 2fr;
  margin-top: 38px;
}

.search-results-popup {
  max-height: 300px;
  overflow-y: auto;
  border: 0.5px solid #e8e8e9;
  border-radius: 8px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1),
    0 1px 5px 0 rgba(0, 0, 0, 0.1);
}

.fetch-suggestion-loader {
  stroke: #5e2368;
}

.fetch-subscriptions-loader {
  text-align: center;
  margin-top: 50px;
}

.table {
  margin-top: 50px;

  &_header {
    &_id {
      padding-left: 25px;
      font-size: 15px;
    }
  }

  .ag-body-viewport {
    min-height: 3.25rem !important;
  }
}

.subscription {
  &_table-title,
  &_table-app-name-container {
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  &_table-unpublished-app-name {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_app-privacy-protection {
    padding-top: 4px;
    fill: rgba(21, 27, 37, 0.7);
  }

  &_details-dialog {
    width: 80vw !important;
    max-height: 90vh !important;
    overflow-y: auto !important;
  }

  &_details-privacy-protection {
    display: inline-block;
    margin-left: 0.15rem;
  }

  /* TODO remove this once subscription details are brought out onto their own page */
  &_details-fields-dialog {
    z-index: 7 !important;
    overflow-y: auto !important;
  }

  &_body-grid {
    display: grid;
    line-height: 35px;
    padding: 5px 10px;
    grid-template-columns: 1fr 2fr;
    border-bottom: 1px solid #e0e0e0;
  }

  &_loader {
    height: 10px;
    width: 10px;
    border-radius: 100%;
    display: inline-block;
    margin: 1px;
    animation: pulse 1s ease-in infinite;
    -webkit-animation: pulse 1.2s ease-in infinite;
  }
}

/* background overlay */
/* TODO remove this once subscription details are brought out onto their own page */
#subscription_details-fields-dialog-portal > div > div > :last-child {
  z-index: 6 !important;
}

/* put the tooltip above the dialog */
#api-privacy-protection-info-tooltip,
#app-privacy-protection-info-tooltip {
  z-index: 10;
}

.usage-report-page-content{
  display: flex;
  flex-direction: column;
  .org-search-bar{
    flex-direction: row;
    padding-top: 5px;
  }
  .usage-report-back-button{
    align-items: flex-start;
  }
}

.fdn-card__implementation-metrics-header, .fdn-card__primary-content-bar-graph{
  margin-top: 10px;
}

.search-results-popup{
  margin-top: 35px;
}
.month-to-date-usage-report-button{
  margin-left: 10px !important;
}
.fdn-metric__note{
  text-align: center!important;
}

.fdn-content__action-button{
  text-align: center!important;
}

.page-section__usage-report{
  .mdc-layout-grid__cell--align-top{
    text-align: end;
  }
}