.fdn-barchart {
  height: 125px;
  display: flex;

  &--container {
    height: 100%;
    width: 70%;
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    position: relative;
  }

  &--bar {
    background-color: #aaa;
    width: 25px;
    align-self: baseline;
    z-index: 5;
    margin-top: auto;
  }

  &--guidebox {
    width: 10%;
    position: relative;
  }

  &--guidelabel {
    position: absolute;
    right: 6px;
    font-size: 12px;

    &.top {
      top: -8px;
    }

    &.half {
      top: calc(50% - 8px);
    }
    &.base {
      top: calc(100% - 8px);
    }
  }

  &--guide {
    position: absolute;
    width: 100%;
    border-bottom: 1px solid #bbb;

    &.top {
      top: 0px;
    }

    &.half {
      top: 50%;
    }
  }

  &--key {
    width: 40%;
    overflow-y: scroll;

    ul {
      padding: 0px 0px 0px 8px;
      margin: 0px;
      list-style: none;

      li {
        display: flex;
        align-items: center;
        padding: 2px 0px 2px 8px;
        font-size: 14px;

        .fdn-barchart--key-icon {
          width: 10px;
          min-width: 10px;
          height: 8px;
          border-radius: 2px;
          margin-right: 4px;
        }
      }
    }
  }
}
