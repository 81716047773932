.table-container-coupon>div {
  height: 481px !important;
}

.table-header-coupon {
  display: flex;
  margin: 16px;
}

.table-header-coupon>div {
  width: 900px !important;
}

.table-header-coupon>button {
  margin-left: 16px;  
}

.coupon-associate-button {
  float: right;
  margin-bottom: 8px !important;
}

.coupon-associate-dialog {
  margin-bottom: 16px;
  margin-top: 16px;
}

.coupon-association-create-footer {
  float: right;
}
.coupon-create-app-details {
  margin-left: 16px;
}

.coupon-selection-dropdown {
  margin-top: 8px;
}

.body-loader {
  text-align: center;
  margin: 64px;
  font-size: 18px;
  font-weight: 600;
  font-family: "Open Sans", "Roboto", sans-serif;
}

.coupon-association-cancel-button {
  margin-right: 8px !important;
}

.coupon-association-app-search {
  margin-top: 8px;
}