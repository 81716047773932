.date-bar .cdk-date-range-picker--dialog-wrapper {
  float: left !important;
}
.date-bar {
  width: 300px !important;
}
.date-bar .mdc-text-field__input {
  width: 260px !important;
}

.date-bar .filter-date-range {
  width: 260px !important;
}

.filter-input-container {
  display: flex;
  align-items: baseline;
}
