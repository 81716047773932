.app-details-title {
  height: 40px;
  width: 350px;
  color: #151b25;
  font-family: Montserrat;
  font-size: 34px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
}

.app-details-body {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 617px;
}

.app-details-body > div:first-child div:last-child {
  display: flex;
}

.app-details-body > div:nth-child(2) span {
  font-weight: 500;
  height: 1.35rem;
}
.bundle-create-page {
  margin: 16px;
}
.bundle_spec-card {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(21, 27, 37, 0.5);
  border-radius: 4px;
  position: relative;
  height: 100%;
}
.bundle_card_top_div {
  padding: 16px 16px 0px 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.bundle_card_middle_div {
  padding: 8px 0px 8px 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.bundle_card_bottom_div {
  padding: 0px 16px 16px 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.background {
  background: rgba(21, 27, 37, 0.15);
}

.bundle_card_logo {
  display: inline-flex;
  column-gap: 16px;
  align-items: center;
  height: 48px;
}
.api-directory__add-api-link {
  float: right;
  margin-right: 56px;
  margin-top: 29px;
}
.bundle_title_3 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  min-height: 16px;
  max-height: 64px;
}
.bundle_card_description {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  color: #151b25;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin-top: 8px;
  height: 60px;
}
.main-page-wrapper_1 {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.bundle_apis_scroll {
  max-height: 65px;
  height: 81px;
}

.bundle_apis_scroll_gray {
  max-height: 65px;
  overflow-y: scroll;
  height: 81px;
}

.api-bundles {
  height: 32px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.18px;
  color: #151b25;
  flex: none;
  order: 0;
}

.list-apis {
  position: absolute;
  width: 383px;
  height: 16px;
  left: 16px;
  top: 144px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;

  display: flex;
  align-items: center;

  color: #151b25;
}

.list_apis {
  width: 383px;
  height: 16px;
  left: 16px;
  top: 144px;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #151b25;
}

.header-body-wrapper {
  width: 864px;
  padding-right: 32px;
  padding-bottom: 32px;
  padding-left: 32px;
}

.header-body-wrapper1 {
  width: 100%;
}

.header-title-container-new {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 16px;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
.bundle_card__ruler {
  height: 1px;
  width: 100%;
  background-color: gray;
  opacity: 0.45;
}
.bundle_card-edit-button {
  margin-right: 8px;
}
.bundle_card-share-button {
  margin-right: 8px;
}
.bundle_card-delete-button {
}
.create_bundle_list_button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 27px;
}
.create_top_bundle_page {
  display: flex;
  justify-content: space-between;
}
.bundle_title_2 {
  font-weight: 600;
}
.api-font {
  color: rgb(107, 37, 116);
}
.bundle_card-status-button {
  padding-left: 8px;
}
.bundle_card_status_green {
  display: flex;
  flex-direction: row;
  /* align-items: flex-end; */
  justify-content: flex-end;
  color: rgb(37 116 48);
  font-weight: 700;
  font-size: 15px;
  line-height: 13px;
}
.bundle_card_status_red {
  display: flex;
  flex-direction: row;
  /* align-items: flex-end; */
  justify-content: flex-end;
  color: rgb(116 105 37 / 74%);
  font-weight: 700;
  font-size: 15px;
  line-height: 13px;
}

.create-bundle-card {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.select-apis-table {
  border-bottom: 1px solid #bdc3c7;
  margin-bottom: 1.5rem;
}

.select-apis-table-content .ag-body-viewport {
  max-height: 18rem;
  overflow-y: auto !important;
}

.create-bundle-title {
  font-family: Roboto !important;
  font-weight: 400;
  font-size: 22.5px;
  line-height: 30px;
}

.bundle-visibility-div{
  display: flex;
  align-items: center;
  line-height:  0px;
}

.visibility-checkbox{
  font-family: Roboto !important;
  font-weight: 500;
  font-size: 13.1px;
  line-height: 20.63px;
  letter-spacing: 0.09px;
  padding-right: 0.5rem !important;
}

.error-div{
  text-align: center;
  height: 300px;
}
.search_and_create_bundle_button_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.logo_name_icon_wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.bundle-search-box {
  width: 379px;
  margin-right: 15px;
}
.create-bundle-button {
  width: 193px;
}
.bundle_share_card_title {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.18000000715255737px;
  text-align: left;
}

.bundle_share_card_container {
  padding: 25px;
}
.bundle_share_card_description {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  padding-top: 20px;
}
.org_id_input_container {
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 60%;
  display: flex;
  align-items: center;
}
.bundle_share_button {
  margin-left: 15px;
}
.org_access_table {
  border: 1px solid #c4c5c8;
  border-radius: 8px;
}
.payment-history-table-div {
  padding-bottom: 15px;
}
.org_access_table_header {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  padding: 15px;
}

.back_to_bundles_button {
  background-color: white !important;
  color: #0e79cc !important;
  border-radius: 0px !important;
  margin-left: -40px !important;
  margin-bottom: 100px !important;
  font-family: "Raleway", sans-serif !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
}
.bundle_share_dialog_box {
  max-width: 50vw !important;
}
.ellipsis_cell_renderer{
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
}
